import { useState } from "react";
import { Form, OverlayTrigger, Popover, Stack } from "react-bootstrap";
import styles from "./contacts.module.css";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
import { Contact, ContactType, DISABLED_STATUES, Task } from "@/components/tasks/types";
import { getContactIcon, getContactType } from "@/components/utils/contact-utils";

const ContactsInput = ({ task, disabled, type, onUpdate, showAll = true } :
  {task: Task, disabled?: boolean, type?: ContactType, onUpdate?: () => void, showAll?: boolean }) => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  const getContacts = () => {
    if (disabled && type !== undefined)
      return task.contacts.filter(c => c.contactType === type);
    else
      return task.contacts;
  };
  const [contacts, setContacts] = useState<Contact[]>(getContacts());
  const [inputValue, setInputValue] = useState('');
  
  if (DISABLED_STATUES.includes(task.status))
    disabled = true;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addContact(e);
    }
  };

  const addContact = async (e) => {
    if (e.target.checkValidity()) {
      let value = inputValue.trim();
      if (e.key === 'Enter') {
        e.preventDefault();
        const accessToken = await getToken();
        const response = await fetch(`/api/v1/contacts`, {
          method: "POST",
          body: JSON.stringify({ contact: { taskId: task.id, contact: value,
            contactType: getContactType(value) } }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const newContact = await response.json();
        if (response.status === 201) {
          railsToast({ info: "Contact has been added!" }, response.status);
          task.contacts.push(newContact.contact);
          if (task.taskUpdates)
            task.taskUpdates.push(newContact.taskUpdate);
          setContacts([...task.contacts]);
          setInputValue('');
          onUpdate();
        }
        else
          railsToast(newContact, response.status);
        e.target.form.classList.remove('was-validated');
      }
    } else {
      e.target.form.classList.add('was-validated');
    }
  }

  const removeContact = async (contact, index) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/contacts/${contact.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      railsToast({ info: "Contact has been deleted!" }, 404);
      task.contacts.splice(index, 1);
      if (task.taskUpdates)
        task.taskUpdates.push(await response.json());
      setContacts([...task.contacts]);
      onUpdate();  
    }
    else
      railsToast(await response.json(), response.status);
  };

  const popoverList = () => (
    <Popover id="popover-basic" className={styles.popover_lg}>
      <Popover.Body>
        <Stack gap={2}>
          {contacts.map((contact, index) => (
            <div className="contact border rounded p-1 me-1 d-flex">
              <i className={"me-1 " + getContactIcon(contact.contactType)} />
              {contact.contact}
              <div className="ms-auto">{disabled ? <></> : <i className={"h5 ms-1 bi bi-x-square " + styles.close_btn} role="button"
                onClick={async () => await removeContact(contact, index)}></i>}</div>
            </div>))}
        </Stack>
      </Popover.Body>
    </Popover>
  );

  const getType = () : string => {
    switch (task.schedulingMethod) {
      case ContactType.PHONE:
        return "tel";
      case ContactType.PORTAL:
        return "text";
      default:
        return "email";
    }
  }

  return (<Form>
    <div className="form-group">
      <Stack>
        <Stack direction="horizontal" className="text-nowrap">
          {contacts.map((contact, index) => showAll || index === 0 ? (
            <div className="contact border rounded p-1 me-1 d-flex">
              <i className={"me-1 " + getContactIcon(contact.contactType)} />
              {contact.contact}
              <div>{disabled ? <></> : <i className={"h5 ms-1 bi bi-x-square " + styles.close_btn} role="button"
                onClick={async () => await removeContact(contact, index)}></i>}</div>
            </div>) : <></>)}
          {disabled ? <></> : <Form.Control type={getType()} id="contact-input"
            className="contact-input" required value={inputValue}
            pattern={task.schedulingMethod === ContactType.PHONE ? '^\\d{9,14}$' : null}
            placeholder="Enter a contact and press Enter" onKeyDown={handleKeyDown}
            onChange={e => setInputValue(e.target.value)} />}
        </Stack>
        {showAll || contacts.length < 2 ? <></> : (
          <OverlayTrigger trigger="click" rootClose={true} placement="auto" overlay={popoverList()}>
            <div role="button" className={styles.more}><a className="primary">more...</a></div>
          </OverlayTrigger>
        )}
      </Stack>
    </div>
  </Form>);
};

export default ContactsInput;