import { Button, Form, Modal } from "react-bootstrap";
import { ContactType, Task, TaskType } from "@/components/tasks/types";
import { useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
import { UpdateType } from "@/components/task-updates/types";

const ContactTypeInput = ({ task, onUpdate } :
  { task: Task, onUpdate: () => void }) => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  const [schedulingMethod, setSchedulingMethod] = useState<ContactType>(task.schedulingMethod);
  const [show, setShow] = useState(false);

  const updating = (contactType: ContactType) => {
    setSchedulingMethod(contactType);
    setShow(true);
  };

  const update = async () => {
    setShow(false);
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({ task: { schedulingMethod: schedulingMethod } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const status = response.status;
    const json = await response.json();
    if ([200].includes(status)) {
      railsToast({ info: "Task scheduling method has been updated!" }, status);
      task.schedulingMethod = schedulingMethod;
      task.contacts = [];
      for (const update of json) {
        if (update.updateType === UpdateType.CONTACTS &&
          update.value1.startsWith('add')) {
          task.contacts.push({ taskId: task.id, contact: update.value2,
            contactType: task.schedulingMethod })
        }
      }
      onUpdate();
    } else {
      railsToast(json, status);
      setSchedulingMethod(task.schedulingMethod);
    }
  };

  const cancel = () => {
    setSchedulingMethod(task.schedulingMethod);
    setShow(false);
  };

  return (task.taskType === TaskType.PRESET ? <></> :
    <>
      <Form.Select value={schedulingMethod} style={{minWidth: '100px', maxWidth: '200px'}}
        onChange={async (e) => updating(e.target.value as ContactType)}>
        <option value={ContactType.EMAIL}>Email</option>
        <option value={ContactType.PHONE}>Phone</option>
        <option value={ContactType.PORTAL}>Portal</option>
      </Form.Select>
      <Modal show={show} onHide={cancel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change scheduling method?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Changing schedule method will remove all contacts, do you want to continue?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={cancel}>No</Button>
          <Button onClick={update}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactTypeInput;