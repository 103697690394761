import { Stack, Image } from "react-bootstrap";
import { ContactType, Task, TaskType } from "../../types";
import iconLogo from "../../../layout/icon-logo.png";
import moment from "moment-timezone";
import { getShortDateTime } from "@/components/utils/task-utils";
import { UpdateType } from "@/components/task-updates/types";
import { getContactIcon } from "@/components/utils/contact-utils";
import { IconExternalLink, IconMail } from "@tabler/icons-react";

const TaskUpdates = ({ task } : { task: Task }) => {
  const updates = () => {
    let updates = [];
    let contacts = [...task.contacts];
    let lastTime = task.scheduledAt;
    let lastSchedulingMethod = task.schedulingMethod;

    for (let i = task.taskUpdates.length - 1; i >= 0; i--) {
      updates.push(<Stack direction="horizontal" gap={3} className="align-items-start mt-3">
        <div className="p-2">
          {task.taskUpdates[i].triggeredBy?.name === 'HubFlow' ?
          <Image src={iconLogo} title="HubFlow" width="32" /> :
          (task.taskUpdates[i].triggeredBy?.name === 'TMS' ? 
          <IconExternalLink size={32}><title>TMS</title></IconExternalLink> :
          (task.taskUpdates[i].triggeredBy ?
          <IconMail size={32}><title>{task.taskUpdates[i].triggeredBy.address}</title></IconMail> :
          <div className="cl-internal-11ewti4">
            <Image src="https://img.clerk.com/eyJ0eXBlIjoiZGVmYXVsdCIsImlpZCI6Imluc18yZUZtZGtjRkpJRzljZ3lKNmZnSHVWdkJMdUwiLCJyaWQiOiJ1c2VyXzJrV1pNekNuc1FoUjFxd0wwMG05U1BDSmVUWSIsImluaXRpYWxzIjoiQUEifQ?width=160"
              title={task.taskUpdates[i].createdByName} width="100%" height="100%" />
          </div>))}
        </div>
        <Stack gap={1}>
          <Stack direction="horizontal" gap={3} className="justify-content-between align-items-start">
            <div className="fw-bold" title={task.taskUpdates[i].triggeredBy?.address}>
              {task.taskUpdates[i].triggeredBy?.name?? task.taskUpdates[i].createdByName}
            </div>
            <div className="pe-3">{moment(task.taskUpdates[i].createdAt).fromNow()}</div>
          </Stack>
          {task.taskUpdates[i].updateType === UpdateType.CONTACTS ?
            <div>Changed contacts: {task.taskUpdates[i].value1} {task.taskUpdates[i].value2}</div> :
            (task.taskUpdates[i].updateType === UpdateType.STATUS ?
            <Stack direction="horizontal" gap={2}>
              Changed status: <del className="text-capitalize">{task.taskUpdates[i].value1.replaceAll('_', ' ')}</del>
              <b className="text-capitalize">{task.taskUpdates[i].value2.replaceAll('_', ' ')}</b>
            </Stack> : (task.taskUpdates[i].updateType === UpdateType.MESSAGE ?
              <div dangerouslySetInnerHTML={{ __html: task.taskUpdates[i].value1 }}></div> : 
              (task.taskUpdates[i].updateType === UpdateType.SYNCED ?
              <div>Appointment time has been updated on TMS!</div> :
              (task.taskUpdates[i].updateType === UpdateType.PLANNED_AT ? <Stack direction="horizontal" gap={2}>
                Changed appointment time:
                <del>{task.taskUpdates[i].value1.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*$/, '$2/$3 $4:$5')}</del>
                <b>{task.taskUpdates[i].value2.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*$/, '$2/$3 $4:$5')}</b>
              </Stack>:
              (task.taskUpdates[i].updateType === UpdateType.SCHEDULED_AT ? 
              <Stack direction="horizontal" gap={2}>
                Changed appointment time:
                <del>{getShortDateTime(new Date(task.taskUpdates[i].value1), task.timezone)}</del>
                <b>{getShortDateTime(new Date(task.taskUpdates[i].value2), task.timezone)}</b>
              </Stack> :
              <Stack direction="horizontal" gap={2}>
                Changed {task.taskUpdates[i].updateType === UpdateType.SCHEDULING_METHOD ?
                  'scheduling method' : 'confirmation number'}:
                <del>{task.taskUpdates[i].value1}</del>
                <b>{task.taskUpdates[i].value2}</b>
              </Stack>)))))}
        </Stack>
      </Stack>);
      switch(task.taskUpdates[i].updateType) {
        case UpdateType.SCHEDULED_AT:
          lastTime = new Date(task.taskUpdates[i].value1);
          break;
        case UpdateType.SCHEDULING_METHOD:
          lastSchedulingMethod = task.taskUpdates[i].value1 as ContactType;
          break
        case UpdateType.CONTACTS:
          const actions = task.taskUpdates[i].value1.split(' ');
          if (actions[0] == 'added') {
            contacts = contacts.filter(c => c.contact !== task.taskUpdates[i].value2);
          } else {
            contacts.push({ contact: task.taskUpdates[i].value2,
              contactType: actions[1] as ContactType, taskId: task.id });
          }
          break;
      }
    }
    if (task.taskType === TaskType.SCHEDULING) {
      updates.push(<Stack direction="horizontal" gap={3} className="align-items-start my-3">
        <div className="p-2">
            <Image src={iconLogo} title="HubFlow" width="32" />
        </div>
        <Stack gap={1}>
          <Stack direction="horizontal" gap={3} className="justify-content-between align-items-start">
            <div className="fw-bold">HubFlow</div>
            <div className="pe-3">{moment(task.createdAt).fromNow()}</div>
          </Stack>
          <Stack gap={2}>
            <span>Task created</span>
            <Stack direction="horizontal">
              <span>Appointment time suggested:</span>
              <span className="ms-2">{getShortDateTime(new Date(lastTime), task.timezone)}</span>
            </Stack>
            <Stack direction="horizontal">
              <span>Scheduling method:</span>
              <span className="ms-2 text-capitalize">{lastSchedulingMethod}</span>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <span>Contact identified:</span>
              {contacts.map(contact =>
                <div className="contact border rounded p-1 me-1 d-flex">
                  <i className={"me-1 " + getContactIcon(contact.contactType)} />
                  {contact.contact}
                </div>)}
            </Stack>
          </Stack>
        </Stack>
      </Stack>);
    }
    return updates;
  }

  return (
    <Stack>
      {updates()}
    </Stack>
  );
};

export default TaskUpdates;
